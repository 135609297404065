import React from "react";
import "react-toastify/dist/ReactToastify.css";

import "./styles/globals.scss";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Authentication from "./pages/Authentication";
import MainDashboard from "./pages/Dashboard/MainDashboard";
import Chat from "./pages/Dashboard/Chat";
import ClassRoom from "./pages/Dashboard/ClassRoom";
import Student from "./pages/Dashboard/Student";
import CalendarHome from "./pages/Calendar/CalendarHome";
import calendarClassList from "./pages/Calendar/calendarClassList";
import CalendarClassRoomDetails from "./pages/Calendar/CalendarClassRoomDetails";
import Analytics from "./pages/Analytics";
import PrivateRoute from "./components/PrivateRoute";
import WeekDetailsAnalytics from "./pages/WeekDetailsAnalytics";
import LeaderboardHome from "./pages/Leaderboard/LeaderboardHome";
import Profile from "./pages/Profile";
const Routes = () => {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/" component={Authentication} exact />
          <PrivateRoute path="/dashboard" component={MainDashboard} exact />
          <PrivateRoute
            path="/dashboard/classroom/:id"
            component={ClassRoom}
            exact
          />

          <PrivateRoute path="/chat" component={Chat} exact />
          <PrivateRoute
            path="/dashboard/students/:classId/:id"
            component={Student}
            exact
          />
          <PrivateRoute path="/calendar" component={CalendarHome} exact />
          <PrivateRoute
            path="/calendar/classes"
            component={calendarClassList}
            exact
          />
          <PrivateRoute
            path="/calendar/classes/:class"
            component={CalendarClassRoomDetails}
            exact
          />
          <PrivateRoute path="/analytics" component={Analytics} exact />
          <PrivateRoute
            path="/analytics/details/:classId/:id"
            component={WeekDetailsAnalytics}
            exact
          />
          <PrivateRoute path="/leaderboard" component={LeaderboardHome} exact />
          <PrivateRoute path="/profile" component={Profile} exact />
        </Switch>
      </Router>
    </div>
  );
};

export default Routes;
