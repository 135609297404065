import React from "react";

const LoadingSpinner = () => {
  return (
    <div className="loadingMask">
      <div className="bulbaSpinner">
        <div className="bulbaSpinnerInner one" />
        <div className="bulbaSpinnerInner two" />
        <div className="bulbaSpinnerInner three" />
      </div>
    </div>
  );
};

export default LoadingSpinner;
