import React, { useState } from "react";
import stemUnicornIcon from "../assets/stemUnicornIcon.svg";
import homeIconFinal from "../assets/homeIconFinal.svg";
import calendarIconFinal from "../assets/calendarIconFinal.svg";
import chatIconFinal from "../assets/chatIconFinal.svg";
import analyticsIconFinal from "../assets/analyticsIconFinal.svg";
import settings from "../assets/settings.svg";
import personIconFinal from "../assets/personIconFinal.svg";
import leaderboardIcon from "../assets/leaderboardIcon.svg";
import { Link } from "react-router-dom";
import config from "../config";
import noAvatar from "../assets/noImage.png";

const Sidebar = ({ section = "home" }) => {
  const handleItem = () => {};
  const logOut = () => {
    localStorage.removeItem("userData");
    window.location.reload();
  };

  const userData = JSON.parse(localStorage.getItem("userData")).user;

  return (
    <div className="left-Sidebar">
      <div className="outer">
        <Link to="/dashboard">
          <div className="topIcon">
            <img src={stemUnicornIcon} alt="stemIcon" />
          </div>
        </Link>
        <div className="middle-Content-sidebar">
          <Link to="/dashboard">
            <div className="groupItem" onClick={handleItem}>
              <img src={homeIconFinal} alt="" />

              {section === "home" && <div className="active"></div>}
            </div>
          </Link>
          <Link to="/calendar">
            {" "}
            <div className="groupItem">
              <img src={calendarIconFinal} alt="" />
              {section === "calendar" && <div className="active"></div>}
            </div>
          </Link>
          {/***
          <Link to="/chat">
            {" "}
            <div className="groupItem">
              <img src={chatIconFinal} alt="" />

              {section === "chat" && <div className="active"></div>}
            </div>
          </Link>
           ***/}
          <Link to="/analytics">
            {" "}
            <div className="groupItem">
              <img src={analyticsIconFinal} alt="" />
              {section === "analytics" && <div className="active"></div>}
            </div>
          </Link>
          <Link to="/leaderboard">
            {" "}
            <div className="groupItem">
              <img src={leaderboardIcon} alt="" />
              {section === "leaderboard" && <div className="active"></div>}
            </div>
          </Link>
          <Link to="/profile">
            <div className="groupItem">
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  overflow: "hidden",
                  borderRadius: "50%",
                }}
              >
                <img
                  src={
                    userData.avatar
                      ? `${config.app.mediaUrl}${userData.avatar}`
                      : noAvatar
                  }
                  alt=""
                />
              </div>
              {section === "profile" && <div className="active"></div>}
            </div>
          </Link>
        </div>
        {/* <div className="individual"> */}

        {/* </div> */}
        <div className="settings" onClick={logOut}>
          <img style={{ cursor: "pointer" }} src={settings} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
