import React from "react";
import NumberFormat from "react-number-format";

const StatBox = (props) => {
  return (
    <div className="outer d-flex-row">
      <div className="inner">
        <div className="top">{props.title}</div>
        <div className="bottom d-flex-row">
          {props.image && <img src={props.image} alt="Image" />}
          <div className="stat">
            <NumberFormat
              value={Math.ceil(props.value)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={props.prefix}
              className="cvPrice"
              decimalScale={0}
            />
            <span> {!props.prefix ? "%" : ""}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatBox;
