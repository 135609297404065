import React, { useState, useEffect, forwardRef } from 'react'
import TableCell from '@mui/material/TableCell'
import { Link } from 'react-router-dom'
import {GlobalDialog} from '../GlobalDialog'
import DatePicker from 'react-datepicker'
import Calendar from '../../assets/calendar.svg'
import 'react-datepicker/dist/react-datepicker.css'
export default function CalendarClassListStudentCell({ row }) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [startDate, setStartDate] = useState(new Date())
  const style = {
    width: '327px',

    height: '40px',
    marginTop: '11px',

    border: '1px solid #E1DEDE',
    padding: '5px',
    paddingLeft: '10px',
    background: 'white',
    textAlign: 'left',
  }
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className='custom-date-calendar' style={{ position: 'relative' }}>
      <button
        className='example-custom-input-1'
        style={style}
        onClick={onClick}
        ref={ref}
      >
        {value}
      </button>
      <img src={Calendar} alt='' />
    </div>
  ))

  return (
    <>
      <GlobalDialog open={open} handleClose={handleClose}>
        <div className='dialog-invite'>
          <div className='header d-flex-row'>
            <span>Availability</span>
            {/* <img
              src={close}
              style={{ cursor: 'pointer' }}
              alt='Close'
              onClick={handleClose}
            /> */}
          </div>
          <div className='main main-Calendar-dialog'>
            <div className='inner'>
              <div className='groupItem-invite'>
                <label htmlFor=''>Time Given</label>
                <DatePicker
                  selected={startDate}
                  dateFormat='MM/d/yyyy'
                  onChange={(date) => setStartDate(date)}
                  customInput={<ExampleCustomInput />}
                />{' '}
              </div>
              <div className='groupItem-invite'>
                <label htmlFor=''>New Time</label>
                <DatePicker
                  selected={startDate}
                  dateFormat='MM/d/yyyy'
                  onChange={(date) => setStartDate(date)}
                  customInput={<ExampleCustomInput />}
                />{' '}
              </div>
            </div>

            <div className='btns'>
              <button className='cancel-btn' onClick={handleClose}>
                Cancel
              </button>
              <button className='invite-btn'>Save</button>
            </div>
          </div>
        </div>
      </GlobalDialog>
      <TableCell align='left' className='introRow'>
        <img className='img-calendar' src={row.intro.image} />
        <span>{row.intro.name}</span>
      </TableCell>
      <TableCell align='left'>{row.gender}</TableCell>
      <TableCell align='left'>{row.email}</TableCell>
      <TableCell align='left'>
        <div
          className='pause'
          style={{ cursor: 'pointer' }}
          onClick={handleOpen}
        >
          {' '}
          Availability
        </div>
      </TableCell>
    </>
  )
}
