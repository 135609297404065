import React from 'react'
import Modal from '@mui/material/Modal'
const GlobalDialog = (props) => {
  return (
    <>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='container-dialog calendar-dialog'>{props.children}</div>
      </Modal>
    </>
  )
}

export default GlobalDialog
