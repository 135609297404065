import React from "react";
import Modal from "@mui/material/Modal";
const GlobalDialog = (props) => {
  return (
    <div className="">
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="container-dialog">{props.children}</div>
      </Modal>
    </div>
  );
};
const AnalyticsModal = (props) => {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-container-analytics">
          <div className="modal-container-inner-analytics">
            <div className="modal-header" style={{ marginBottom: "45px" }}>
              {props.heading}
            </div>
            {/* <button className='btn-analytics'>Export</button> */}
          </div>
          {props.children}
        </div>
      </Modal>
    </div>
  );
};

export { GlobalDialog, AnalyticsModal };
