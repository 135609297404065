import React from 'react'
import Sidebar from '../../components/Sidebar'
import TopContent from '../../components/TopContent'
import BreadCrumbList from '../../components/BreadCrumbList'
import RightSidebar from '../../components/RightSidebar'
import TableMain from '../../components/Table'
import search from '../../assets/search.svg'

const calendarClassList = () => {
  // table data
  const headCells = [
    {
      id: 'classroom',
      label: '',
      numeric: false,
      disablePadding: false,
    },

    {
      id: 'button',
      label: '',
      numeric: false,
      disablePadding: false,
    },
  ]
  const data = [
    {
      classRoom: 'Class A',
    },
    {
      classRoom: 'Class B',
    },
    {
      classRoom: 'Class C',
    },
    {
      classRoom: 'Class D',
    },
    {
      classRoom: 'Class E',
    },
  ]

  // end of table data
  const values = [
    {
      name: 'Dashboard',
      to: '/dashboard',
    },
    {
      name: 'Calendar',
      to: '/calendar',
    },
  ]
  return (
    <div className='mainContainer'>
      <div className='mainDashboard'>
        <Sidebar section='calendar' />
        <div className='main_section'>
          <div className='main_section_inner'>
            {/* <TopContent /> */}
            <div className='middle-Content middle-Content-Student'>
              <div className='heading-classroom'>
                <BreadCrumbList values={values} />
              </div>
              <div className='table-Content calendarTableContent'>
                <div className='top d-flex-row'>
                  <div className='heading'>Classroom</div>
                  <div className='right_Content d-flex-row'>
                    <div className='left'>
                      <input type='text' placeholder='Search...' />
                      <img src={search} alt='' />
                    </div>
                  </div>
                </div>
                <div className='underline'>
                  <div className='left'></div>
                  <div className='right'></div>
                </div>
                <div className='tableCalendar'>
                  {/* </div> */}
                  <TableMain
                    headCells={headCells}
                    resultData={data}
                    topic='calendarClassLists'
                    tableWidth='500'
                  />
                </div>
              </div>
            </div>
            <RightSidebar />
          </div>
        </div>
      </div>
    </div>
  )
}

export default calendarClassList
