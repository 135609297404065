import axios from "axios";
import config from "../config";
import { isServer } from "../utils/helper";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: config.app.apiUrl,
});
// get user token
const teacherToken = JSON.parse(localStorage.getItem("userData"));
const token = teacherToken ? teacherToken.accessToken : "";

api.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

api.defaults.withCredentials = true;

// api.interceptors.request.use(function (config) {
//   const accessToken = localStorage.getItem("accessToken");
//   // if (accessToken) {
//   //   api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
//   // }

//   config.headers.Authorization = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyNTNlN2YyZmVkMzZmM2RjYWNmY2JiMyIsImlhdCI6MTY0OTgzMzY5NiwiZXhwIjoxNjQ5OTIwMDk2LCJpc3MiOiJhcHBfbmFtZSJ9.4ZJgxmmoETq5aJOL0P3Gy6c5JfRM6slGaScAqRdvltM`;
//   return config;
// });

api.interceptors.response.use(
  async (response) => {
    if (response.status >= 200 && response.status < 300) {
      const data = response.data;
      return Promise.resolve(data);
    }
  },
  async (error) => {
    const { response, request } = error;
    if (response) {
      if (response.status === 401) {
        if (!isServer()) {
          localStorage.removeItem("userData");
        }
        // window.location.href = "/";
      }
      if (response.status === 403) {
        toast.error(response.data.message);
      }
      if (response.status >= 400 && response.status < 500) {
        return Promise.reject(response.data);
      }
    } else if (request) {
      return null;
    }
    return Promise.reject(error);
  }
);

export default api;
