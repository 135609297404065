import React, { useState, useEffect } from "react";
import ErrorMessage from "./ErrorMessage";
import { toast } from "react-toastify";
import api from "../services/api";
import { GlobalDialog } from "./GlobalDialog";
import close from "../assets/close.svg";
import DatePicker from "react-datepicker";
import LoadingSpinner from "./LoaderSpinner";

const Announcement = ({ handleOpen, open, setOpen, refresh1, setRefresh1 }) => {
  const handleClose = () => {
    setErrors("");

    setOpen(false);
  };
  const [imageIs, setImageIs] = useState();
  const [imageIsthis, setImageIsthis] = useState();
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");

  const imageChangeHandler = (e) => {
    const [file] = e.target.files;
    setImageIs(URL.createObjectURL(file));
    setImageIsthis(e.target.files[0]);
  };
  const [errors, setErrors] = useState({});

  const validateErrors = (values) => {
    let errors = {};
    if (values.title === "") {
      errors.title = "Title is required";
    }
    if (values.message === "") {
      errors.message = "Message is required";
    }
    return errors;
  };
  // const [refresh, setRefresh] = useState(false)
  const [startDate, setStartDate] = useState(new Date());

  let schoolId = JSON.parse(localStorage.getItem("userData")).user.schoolId
    ?._id;

  const [apiLoader, setApiLoader] = useState(false);

  const updateProfile = async (e) => {
    e.preventDefault();
    let values = { message, title };
    setErrors(validateErrors(values));

    if (Object.keys(validateErrors(values)).length > 0) {
      return;
    }

    try {
      //   setUpdating(true)
      setApiLoader(true);
      const formData = new FormData();
      // imageIsthis && formData.append("image", imageIsthis);
      formData.append("title", title);
      formData.append("message", message);
      formData.append("startDate", startDate);

      formData.append("classId", classIs);

      if (classIs === "mass") {
        const data = await api.post(
          `/teacher/add/MassAnnouncement`,
          // formData
          {
            title: title,
            message: message,
            date: startDate,
          }
        );
        if (data.ok) {
          setRefresh1(!refresh1);
          setOpen(false);

          toast.success(data.message);
          setApiLoader(false);
        }
      } else {
        const data = await api.post(
          `/teacher/add/announcement/${classIs}`,
          // formData
          {
            title: title,
            message: message,
            date: startDate,
            classId: classIs,
          }
        );
        if (data.ok) {
          setRefresh1(!refresh1);
          setOpen(false);

          toast.success(data.message);
          setApiLoader(false);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      setApiLoader(false);
    }
  };
  const [initialclasses, setInitialClasses] = useState([]);
  let teacherId = JSON.parse(localStorage.getItem("userData")).user._id;

  const getClassrooms = async () => {
    try {
      const data = await api.get(
        `/teacher/view/all/teacher/class/${teacherId}`
      );

      if (data && data.ok) {
        setInitialClasses(data.data);
        if (data.data.length > 0) {
          setClassIs(data.data[0]._id);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [classIs, setClassIs] = useState("");

  useEffect(() => {
    getClassrooms();
  }, []);

  return (
    <>
      <GlobalDialog open={open} handleClose={handleClose}>
        {apiLoader ? <LoadingSpinner /> : ""}
        <div className="dialog-invite">
          <div className="top-header-dialog">
            <img
              src={close}
              style={{ cursor: "pointer" }}
              alt="Close"
              onClick={handleClose}
            />
          </div>
          <form onSubmit={(e) => updateProfile(e)}>
            {/* <div className="image-section d-flex-column">
              <img
                src={imageIs ? imageIs : ""}
                alt="Image"
                className="imageStudent"
              />
              <label htmlFor="image" className="image-change-label">
                Choose Image
              </label>
              <input
                onChange={(e) => imageChangeHandler(e)}
                type="file"
                id="image"
              />
            </div> */}
            <div className="main">
              <div className="groupItem-invite invite-new">
                <label htmlFor="">Title</label>
                <input
                  type="text"
                  value={title}
                  name="title"
                  onChange={(e) => setTitle(e.target.value)}
                />
                {errors.title && (
                  <ErrorMessage message={errors.title} type="danger" />
                )}
              </div>

              <div className="groupItem-invite invite-new">
                <label htmlFor="">Message</label>
                <input
                  type="text"
                  value={message}
                  name="message"
                  onChange={(e) => setMessage(e.target.value)}
                />
                {errors.message && (
                  <ErrorMessage message={errors.message} type="danger" />
                )}
              </div>
              <div className="groupItem-invite invite-new">
                <label>Classroom</label>
                <select
                  name="class"
                  value={classIs}
                  onChange={(e) => setClassIs(e.target.value)}
                  className="select-2"
                >
                  {initialclasses.length > 0 &&
                    initialclasses.map((className, id) => (
                      <option value={className._id}>
                        {className.className}
                      </option>
                    ))}
                  <option value="mass">Mass Announcement</option>
                </select>
              </div>
              <div className="groupItem-invite invite-new">
                <label htmlFor="">Announcement Date</label>
                <DatePicker
                  selected={startDate}
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  onChange={(date) => setStartDate(date)}
                  // customInput={<ExampleCustomInput />}
                />
              </div>
            </div>
            <div
              className="btns d-flex-row"
              style={{ justifyContent: "flex-end", gap: "16px" }}
            >
              <button
                type="button"
                className="cancel-btn"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                //   disabled={updating}
                type="submit"
                className="invite-btn"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </GlobalDialog>
    </>
  );
};

export default Announcement;
