import React from "react";
import studentImage from "../assets/enzo.png";
import config from "../config";
import NumberFormat from "react-number-format";

const StudentRank = (props) => {
  // console.log(props.img);
  return (
    <div className={`score-box d-flex-row ${props.id !== 1 && "score-box-1"}`}>
      <div className="id">{props.id}</div>
      <div className="intro d-flex-column">
        <div className="name">{props.name}</div>
        {/* <div className='username'>tech tycoon</div> */}
      </div>
      <div className="value d-flex-row">
        <span style={{ fontSize: "18px" }}>Company Valuation:</span>{" "}
        <NumberFormat
          value={Math.ceil(props.totalCampnayValuation)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          className="cvPrice"
          decimalScale={0}
        />
      </div>

      <img
        src={
          props.customAvatar
            ? `${config.app.mediaUrl}/api/${props.customAvatar}`
            : props.img
            ? `${config.app.mediaUrl}/api/uploads/admin/character/${props.img}`
            : studentImage
        }
        className="img-student"
        alt="student"
      />
    </div>
  );
};

export default StudentRank;
