import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TopContent from "../components/TopContent";
import BreadCrumbList from "../components/BreadCrumbList";
import api from "../services/api";
import { format } from "date-fns";
import GenerateYear from "../utils/GenerateYear";
import AnalyticsLesson from "../components/AnalyticsLesson";
import QuestionAnalytics from "../components/QuestionAnalytics";
import JsPDF from "jspdf";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// quiz questions

const Analytics = () => {
  let schoolId = JSON.parse(localStorage.getItem("userData")).user.schoolId
    ?._id;

  const values = [
    {
      name: "Dashboard",
      to: "/dashboard",
    },
    {
      name: "Analytics",
      to: "/analytics",
    },
  ];
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // table headers

  const [years, setYears] = useState(GenerateYear());
  const [initialclasses, setInitialClasses] = useState([]);
  let teacherId = JSON.parse(localStorage.getItem("userData")).user._id;
  const [classes, setClasses] = useState([]);

  const getClassrooms = async () => {
    try {
      const data = await api.get(
        `/teacher/view/all/teacher/class/${teacherId}`
      );

      if (data && data.ok) {
        setInitialClasses(data.data);
        if (data.data.length > 0) {
          setClassIs(data.data[0]._id);
        }
        setClasses(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [weekData, setWeekData] = useState();
  const [classIs, setClassIs] = useState("");
  const getWeekData = async () => {
    try {
      const data = await api.get(
        `/teacher/view/class/content/response/${classIs}`
      );

      if (data && data.ok) {
        setWeekData(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getClassrooms();
  }, []);

  // useEffect(() => {
  //   classIs !== '' && getWeekData()
  // }, [classIs])
  // week response on class id
  // get data according to class
  const [classData, setClassData] = useState();
  const getDataByClass = async () => {
    try {
      const data = await api.get(
        `/teacher/view/class/content/response/${classIs}`
      );
    } catch (error) {
      console.error(error);
    }
  };
  // get grades of classes
  const [grades, setGrades] = useState("");
  const getGrades = async () => {
    try {
      const data = await api.get(
        `/teacher/view/class/performance/grades/${classIs}`
      );
      setGrades(data.data?.toFixed(2));
    } catch (error) {
      console.error(error);
    }
  };
  const [timeSpent, setTimeSpent] = useState("");
  const getAvergeTime = async () => {
    try {
      const data = await api.get(`/teacher/total/time/module/${classIs}`);
      function seconds_to_days_hours_mins_secs_str(seconds) {
        var days = Math.floor(seconds / (24 * 60 * 60));
        seconds -= days * (24 * 60 * 60);
        var hours = Math.floor(seconds / (60 * 60));
        seconds -= hours * (60 * 60);
        var minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        return (
          (0 < days ? days + " day, " : "") +
          (0 < hours ? hours + "hour, " : "") +
          (0 < minutes ? minutes + "m " : "") +
          seconds.toFixed(1) +
          "s"
        );
      }
      const time = seconds_to_days_hours_mins_secs_str(
        data.avgTimeSpent.toFixed(1)
      );
      // console.log(time);
      setTimeSpent(time);
    } catch (error) {
      console.log("error", error);
    }
  };
  // get all data of class for graph
  // const getClassData = async () => {
  //   try {
  //     const data = await api.get(
  //       `/teacher/fetch/classData/${schoolId}/${classIs}`
  //     );
  //     console.log("data of class is", data);
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // };
  useEffect(() => {
    if (classIs !== "") {
      // getDataByClass()
      getGrades();
      getAvergeTime();
      // getClassData();
    }
  }, [classIs, schoolId]);
  // generate pdf
  const generatePdf = () => {
    const report = new JsPDF("portrait", "pt", "a4");
    console.log(document.querySelector("#questions"));
    report.html(document.querySelector("#questions")).then(() => {
      report.save("report.pdf");
    });
  };

  const [selectedClass, setSelectedClass] = useState();
  const [selectedContent, setSelectedContent] = useState();
  const [selectedClassContent, setSelectedClassContent] = useState();

  const classSelectChangeHandler = (event) => {
    const { value } = event.target;
    setSelectedClass(value);
  };

  const classSelectClassContentChangeHandler = (event) => {
    const { value } = event.target;
    setSelectedContent(value);
  };

  // const getClassContentOfClass = async () => {
  //   try {
  //     const res = await api.post(
  //       `/teacher/classroomContents/view/${selectedClass}`
  //     );
  //     if (res && res.ok) {
  //       setSelectedClassContent(res.data);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   getClassContentOfClass();
  // }, [selectedClass]);

  // useEffect(() => {
  //   getDecisionTreeAnalytics();
  // }, [selectedContent]);

  // const [decisionTreeData, setDecisionTreeData] = useState([]);

  // const getDecisionTreeAnalytics = async () => {
  //   try {
  //     console.log(selectedClassContent);
  //     const res = await api.get(`/teacher/gameAnalytics/${selectedContent}`);
  //     if (res && res.ok) {
  //       setDecisionTreeData(res.data);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // console.log(decisionTreeData);

  return (
    <div className="mainContainer">
      <div className="mainDashboard">
        <Sidebar section="analytics" />
        <div className="main_section">
          <div className="main_section_inner main_section_inner_chat">
            {/* <TopContent /> */}
            <div className="middle-Content middle-Content-Student middle-Content-Student-new">
              <div className="heading-classroom">
                <BreadCrumbList values={values} />
              </div>
              <div className="tabs-cover d-flex-row">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{ mt: 2, borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
                >
                  <Tab label="Lesson" {...a11yProps(0)} />
                  {/* <Tab label='Game' {...a11yProps(1)} /> */}
                </Tabs>
                <div className="select-section d-flex-row">
                  {value === 0 && (
                    <>
                      {/***
                      <div className="inner d-flex-column">
                        <span>Year</span>
                        <select className="select-1" name="" id="">
                          {years.map((year, index) => (
                            <option key={index} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                      ***/}
                      <div className="inner d-flex-column">
                        <span>Classroom</span>
                        <select
                          name="class"
                          value={classIs}
                          onChange={(e) => setClassIs(e.target.value)}
                          className="select-2"
                        >
                          {initialclasses.length > 0 &&
                            initialclasses.map((className, id) => (
                              <option value={className._id}>
                                {className.className}
                              </option>
                            ))}
                        </select>
                      </div>
                    </>
                  )}

                  <div className="rightSidebar-inner analytics-topbar">
                    <div className="inner-box">
                      <div className="top">
                        <div className="left">
                          <div className="date-classroom">
                            {format(new Date(), "dd MMMM")}
                          </div>
                          <div
                            className="date-classroom date-classroom-year"
                            style={{ textAlign: "left" }}
                          >
                            {format(new Date(), "yyyy")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="analyticsMain" style={{ marginTop: "32px" }}>
                <TabPanel value={value} index={0}>
                  <AnalyticsLesson
                    classId={classIs}
                    grades={grades}
                    timeSpent={timeSpent}
                  />
                </TabPanel>
                {/* <TabPanel value={value} index={1}>
                  <div className="btn-anlytics-outer">
                    <div>
                      <select
                        className="btn-analytics btn-new-export d-flex-end"
                        style={{ marginRight: "5px" }}
                        value={selectedClass}
                        onChange={classSelectChangeHandler}
                      >
                        <option>Choose Class</option>
                        {classes && classes.length > 0
                          ? classes.map((classData, index) => {
                              return (
                                <option key={index} value={classData._id}>
                                  {classData.className}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                    <div>
                      <select
                        className="btn-analytics btn-new-export d-flex-end"
                        style={{ marginRight: "5px" }}
                        value={selectedContent}
                        onChange={classSelectClassContentChangeHandler}
                      >
                        <option>Choose Week</option>
                        {selectedClassContent && selectedClassContent.length > 0
                          ? selectedClassContent.map(
                              (classContentData, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={classContentData._id}
                                  >
                                    {classContentData.title}
                                  </option>
                                );
                              }
                            )
                          : ""}
                      </select>
                    </div>
                    <div
                      onClick={generatePdf}
                      className="btn-analytics btn-new-export d-flex-end"
                    >
                      Export
                    </div>
                  </div>
                  <QuestionAnalytics decisionTreeData={decisionTreeData} />
                </TabPanel> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
