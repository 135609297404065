import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import TopContent from "../../components/TopContent";
import calendarClassroom from "../../assets/calendarClassroom.svg";
import message from "../../assets/message.svg";
import people from "../../assets/people.svg";
import stXaviers from "../../assets/stXaviers.svg";
import logout from "../../assets/logout.svg";
import settings from "../../assets/settings.svg";
import music from "../../assets/music.svg";
import TableMain from "../../components/Table";

import search from "../../assets/search.svg";
import girl from "../../assets/girl.svg";
import plusIcon from "../../assets/plusIcon.svg";
import arrowRight from "../../assets/arrowRight.svg";

import switchIcon from "../../assets/switch.svg";
import close from "../../assets/close.svg";

import GlobalDialog from "../../components/GlobalDialog";
import BreadCrumbList from "../../components/BreadCrumbList";
import api from "../../services/api";
import RightSidebar from "../../components/RightSidebar";

const MainDashboard = ({ match }) => {
  // search

  // get data

  // breadcrumbs
  const values = [
    {
      name: "Dashboard",
      to: "/dashboard",
    },
  ];
  const headCells = [
    {
      id: "classroomName",
      label: "Classroom Name",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "createdDate",
      label: "Created Date",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "assignedTeacher",
      label: "Assigned Teacher",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "studentnumber",
      label: "Student Number",
      numeric: true,
      disablePadding: false,
    },
    {
      id: "button",
      label: "",
      numeric: false,
      disablePadding: false,
    },
  ];

  // apis call
  // get all classrooms
  let teacherId = JSON.parse(localStorage.getItem("userData")).user._id;
  const [language, setLanguage] = useState();
  const [isoCode, setIsoCode] = useState();
  const [classes, setClasses] = useState([]);
  const [initialclasses, setInitialClasses] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);

  const getClassrooms = async () => {
    try {
      const data = await api.get(
        `/teacher/view/all/teacher/class/${teacherId}`
      );

      if (data && data.ok) {
        let totalStudents = 0;

        setInitialClasses(data.data);
        data.data.map((datum) => {
          totalStudents += datum.student.length;
        });
        setTotalStudents(totalStudents);

        setClasses(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getSchoolLanguageOfUse();
    getClassrooms();
  }, []);

  const getSchoolLanguageOfUse = async () => {
    try {
      const res1 = await api.get("/teacher/view/languageOfUse");
      if (res1 && res1.ok) {
        const languageOfUse = res1.data.language;
        if (languageOfUse) {
          setLanguage(languageOfUse.name);
          setIsoCode(languageOfUse.isoCode);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // search
  const [searchItem, setSearchItem] = useState("");
  const performSearch = () => {
    if (searchItem === "") {
      setClasses(initialclasses);
    } else {
      let found = classes.filter(
        (item) =>
          item.className &&
          item.className.toLowerCase().includes(searchItem.toLowerCase())
      );
      setClasses(found);
    }
  };
  useEffect(() => {
    performSearch();
  }, [searchItem]);

  return (
    <div className="mainContainer">
      <div className="mainDashboard">
        <Sidebar section="home" />
        <div className="main_section">
          <div className="main_section_inner">
            {/* <TopContent /> */}
            <div className="middle-Content middle-Content-Classroom">
              <div className="heading-classroom">
                <BreadCrumbList values={values} />
              </div>
              <div className="top-classroom">
                <div className="left">
                  <div className="inner-box">
                    <img src={people} alt="Calendar" />
                    <div className="content">
                      <span className="top">Registered Students</span>
                      <span>{totalStudents}</span>
                    </div>
                  </div>
                  <div className="inner-box">
                    <img src={message} alt="Calendar" />
                    <div className="content">
                      <span className="top">Language of use</span>
                      <span>
                        {language} ({isoCode})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-Content-classroom">
                <div className="top d-flex-row">
                  <span className="heading ">Classroom</span>
                </div>
              </div>

              <div className="table-Content">
                <div className="top d-flex-row">
                  <div className="heading">Classroom</div>
                  <div className="right_Content d-flex-row">
                    <div className="left">
                      <input
                        type="text"
                        name="searchItem"
                        value={searchItem}
                        onChange={(e) => setSearchItem(e.target.value)}
                        placeholder="Search..."
                      />
                      <img src={search} alt="" />
                    </div>
                    {/* <div className='right'>
                      <img src={switchIcon} alt='' />
                    </div> */}
                  </div>
                </div>
                <div className="underline">
                  <div className="left"></div>
                  <div className="right"></div>
                </div>

                <TableMain
                  headCells={headCells}
                  resultData={classes}
                  topic="DashboardCell"
                />
              </div>
            </div>
            <RightSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDashboard;
