import React, { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import { Link } from "react-router-dom";
import api from "../../services/api";
import AvailabilityModel from "../AvailabilityModel";
import moment from "moment";

export default function ClasscontentCell({
  row,
  setRefresh,
  refresh,
  classId,
}) {
  const [loading, setLoading] = useState(false);
  const changeStatus = async () => {
    setLoading(true);
    const obj = {
      status: row.status === "locked" ? "unlocked" : "locked",
    };
    const res = await api.patch(`/teacher/class/content/${row._id}`, obj);
    if (res && res.ok) {
      //window.location.reload();
      setRefresh(!refresh);
      setLoading(false);
    }
  };

  const [open, setOpen] = useState(false);

  const openAvailabilityModel = () => {
    setOpen(true);
  };

  return (
    <>
      <TableCell align="left">
        {/* <Link to={`/dashboard/students/${classId}/${row.class}/${row._id}`}> */}
        {row.title}
        {/* </Link>{" "} */}
      </TableCell>
      <TableCell align="left" style={{ display: "flex" }}>
        <div
          className="pause"
          style={{ cursor: "pointer" }}
          onClick={() => changeStatus(row._id, row.status)}
        >
          {loading ? "loading..." : row.status === "locked" ? "Unlock" : "Lock"}
        </div>
        <div
          className="pause"
          style={{ cursor: "pointer", marginLeft: "10px" }}
          onClick={openAvailabilityModel}
          // onClick={() => changeStatus(row._id, row.status)}
        >
          Auto Unlock
        </div>
      </TableCell>
      <TableCell>
        {row?.availabilityTime ? (
          <div>
            Auto-unlock on{" "}
            {moment(row?.availabilityTime.date).format("DD/MM/YYYY, h:mm:ss A")}
          </div>
        ) : (
          "Not set"
        )}
      </TableCell>
      {open ? (
        <AvailabilityModel
          setAvailabilityModel={setOpen}
          contetDetails={row._id}
        />
      ) : (
        ""
      )}
    </>
  );
}
