import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'

import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { CacheProvider } from '@emotion/react'

import App from './App'

import createCache from '@emotion/cache'
const cache = createCache({
  key: 'css',
  prepend: true,
})

// const rootElement = document.getElementById('root')
// const root = createRoot(rootElement)
ReactDOM.render(
  <React.StrictMode>
    <CacheProvider value={cache}>
      <App />
    </CacheProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
