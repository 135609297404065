export default {
  app: {
    apiUrl:
      process.env.REACT_APP_API_BASE_URL || "https://stem-unicorn.goswivt.com",
    dev: process.env.NODE_ENV === "development",
    uploadUrl:
      "http://localhost:8000/api/v1" || "https://stem-unicorn.goswivt.com/",
    imageUrlLocal: process.env.REACT_APP_PUBLIC_APP_IMAGE_URL || "/api/public",
    socketUrl:
      process.env.REACT_APP_API_SOCKET_URL ||
      "https://stem-unicorn.goswivt.com",
    mediaUrl:
      process.env.REACT_APP_API_BASE_MEDIA_URL ||
      "https://stem-unicorn.goswivt.com",
  },
};
