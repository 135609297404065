import React from "react";

const ErrorMessage = ({ message, type }) => {
  return (
    <div
      className={`alert-msg ${type}-msg`}
      style={{ width: "300px", textAlign: "center", paddingTop: "10px" }}
    >
      {message}
    </div>
  );
};

export default ErrorMessage;
