import { Route, Redirect } from 'react-router-dom'
export const PrivateRoute = ({ component: Component, ...rest }) => {
  let user = localStorage.getItem('userData')

  if (user !== null) {
    return <Route component={Component} {...rest} />
  }
  return <Redirect to='/' />
}
export default PrivateRoute
