import React, { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import { Link } from "react-router-dom";
import api from "../../services/api";
export default function ClassroomCell({ row, setRefresh, refresh, classId }) {
  const [loading, setLoading] = useState(false);
  // change status of students
  const changeStatus = async (id, status) => {
    setLoading(true);
    try {
      await api.patch("/teacher/edit/student/status", {
        studentId: id,
        status: status,
      });
      setRefresh(!refresh);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <TableCell align="left">
        <Link to={`/dashboard/students/${classId}/${row._id}`}>
          {/* <img src={row.intro.image} /> */}
          {/* <span> */}
          {row.name}
          {/* </span> */}
        </Link>{" "}
      </TableCell>
      {/* <TableCell align='left'>{row._id}</TableCell> */}
      {/* <TableCell align='left'>{row.class}</TableCell> */}
      <TableCell align="left">{row.email}</TableCell>
      <TableCell align="left">
        <div
          className="pause"
          style={{ cursor: "pointer" }}
          onClick={() => changeStatus(row._id, row.status)}
        >
          {loading
            ? "loading..."
            : row.status === "active"
            ? "Pause"
            : "Resume"}
        </div>
      </TableCell>
    </>
  );
}
