import React from 'react'
import notificationTop from '../assets/notificationTop.svg'
import search from '../assets/search.svg'

const TopContent = () => {
  return (
    <>
      <div className='notificationArea'>
        <div className='notification_inner'>
          <img src={notificationTop} alt='Notification' />
          <span className='outline'></span>
        </div>
      </div>
      <div className='search-Container'>
        <div className='search-Box'>
          <input type='text' placeholder='Search...' />
          <img src={search} alt='Search' />
        </div>
      </div>
    </>
  )
}

export default TopContent
