import React from "react";
import ClassroomCell from "./ClassroomCell";
import CalendarClassListCell from "./CalendarClassListCell";
import CalendarClassListStudentCell from "./CalendarClassListStudentCell";
import DashboardCell from "./DashboardCell";
import ClasscontentCell from "./ClasscontentCell";
export default function AllTableCells({
  row,
  topic,
  setRefresh,
  refresh,
  classId,
}) {
  // console.log("topic is", topic);
  return topic === "classroomCell" ? (
    <ClassroomCell
      row={row}
      setRefresh={setRefresh}
      refresh={refresh}
      classId={classId}
    />
  ) : topic === "calendarClassLists" ? (
    <CalendarClassListCell row={row} />
  ) : topic === "calendarClassListStudentCell" ? (
    <CalendarClassListStudentCell row={row} />
  ) : topic === "DashboardCell" ? (
    <DashboardCell row={row} />
  ) : topic === "Classcontent" ? (
    <ClasscontentCell
      row={row}
      setRefresh={setRefresh}
      refresh={refresh}
      classId={classId}
    />
  ) : null;
}
