import React, { useState, useEffect, forwardRef } from "react";
import Sidebar from "../../components/Sidebar";
import TopContent from "../../components/TopContent";
import BreadCrumbList from "../../components/BreadCrumbList";
import noImage from "../../assets/noImage.png";

import edit from "../../assets/edit.svg";
import girlImage from "../../assets/girlImage.svg";
import briefcase from "../../assets/briefcase.svg";
import sms from "../../assets/sms.svg";
import arrowDownFinal from "../../assets/arrowDownFinal.svg";
import tick from "../../assets/tickCircle.svg";
import starIcon from "../../assets/starIcon.svg";
import cashIcon from "../../assets/cashIcon.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { GlobalDialog } from "../../components/GlobalDialog";
import close from "../../assets/close.svg";
import personIconFinal from "../../assets/personIconFinal.svg";
import api from "../../services/api";
import { format } from "date-fns";
import ErrorMessage from "../../components/ErrorMessage";
import StatBox from "../../components/StatBox";
import { toast } from "react-toastify";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import config from "../../config";
import RightSidebar from "../../components/RightSidebar";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);
const options = {
  responsive: true,
  scales: {
    yAxes: {
      display: false,
      ticks: {
        display: true,
      },
      grid: {
        display: false,
        zeroLineColor: "transparent",
      },
    },
    xAxes: {
      display: false,
      ticks: {
        display: true,
      },
      grid: {
        display: false,
        zeroLineColor: "transparent",
      },
    },
  },
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    tooltip: {
      backgroundColor: "#E65E41",
      displayColors: false,
      cornerRadius: 10,
      bodyAlign: "center",
      titleAlign: "center",
      padding: {
        left: 16,
        top: 5,
        right: 16,
        bottom: 5,
      },

      titleFont: {
        size: 10,
        family: "Inter",
        weight: 400,
        lineHeight: 1.2,
      },
      bodyFont: {
        size: 16,
        family: "Inter",
        weight: 600,
        lineHeight: 1.9,
      },
      callbacks: {
        label: function (context) {
          let label = "";

          return (label = context.parsed.y + "%");
        },
        title: function (context) {
          let label = "";

          return (label = `23 ${context[0].label} 2021`);
        },
      },
    },

    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};
const labels = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// arrow Down
const ArrowDown = () => {
  return (
    <div>
      <img
        style={{ height: "25px", width: "25px", marginLeft: "10px" }}
        src={arrowDownFinal}
        alt="df"
      />
    </div>
  );
};
const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [20, 40, 100, 400, 500, 20, 40, 1000, 40, 20, 300, 1200],
      borderColor: "#EF5B2C",
      backgroundColor: "white",
      tension: "0.5",
      // pointBorderWidth:"10.59px",
      pointRadius: 0,
      pointHoverRadius: 5,
      fill: true,
      borderWidth: 3,
    },
  ],
};

const data1 = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [20, 40, 100, 400, 500, 20, 40, 1000, 40, 20, 300, 1200],
      borderColor: "#FFD66B",
      backgroundColor: "white",
      tension: "0.5",
      // pointBorderWidth:"10.59px",
      pointRadius: 0,
      pointHoverRadius: 5,
      borderWidth: 3,
    },
  ],
};

const Student = ({ match }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setErrors("");
    // setStudentName('')
    // setStudentEmail('')
    setOpen(false);
    // setImageIs()
    // setImageIsthis()
  };
  const studentId = match.params.id;
  const [name, setName] = useState("");
  const [studentImage, setStudentImage] = useState();

  const values = [
    {
      name: "Dashboard",
      to: "/dashboard",
    },
    {
      name: "Students",
      to: `/dashboard/classroom/${match.params.classId}`,
    },
    {
      name: name,
      to: `/dashboard/students/${match.params.classId}/${studentId}`,
    },
  ];
  const [studentDetails, setStudentDetails] = useState("");
  const [date, setDate] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [attendancePercent, setAttendancePercent] = useState(0);
  const changeStatus = async (id, status) => {
    try {
      await api.patch("/teacher/edit/student/status", {
        studentId: id,
        status: status,
      });
      setRefresh(!refresh);
    } catch (error) {
      console.error(error);
    }
  };
  const getStudentAttendance = async () => {
    try {
      const data = await api.get(
        `/teacher/classroom/attendance/${match.params.id}`
      );
      if (data && data.ok) {
        let percentage = (data.data.attendance.length / data.totalDays) * 100;
        setAttendancePercent(percentage);
      }
    } catch (error) {
      console.error("error is", error);
    }
  };
  const getStudent = async () => {
    try {
      const data = await api.get(
        `/teacher/get/individual/student/${match.params.id}`
      );
      setStudentDetails(data.data);
      let dateIs = format(new Date(data.data.createdAt), "dd MMMM yyyy");

      setDate(dateIs);
      setName(data.data?.name);
      setStudentName(data.data?.name);
      setStudentEmail(data.data?.email);
      setClassName(data.data?.classroom?.className);
      if (data?.data?.avatar) {
        setImageIs(`${config.app.mediaUrl}${data.data?.avatar}`);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getStudent();
  }, [refresh]);
  useEffect(() => {
    getStudentAttendance();
  }, []);

  const [studentName, setStudentName] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [className, setClassName] = useState("");

  const validateErrors = (values) => {
    let errors = {};
    if (values.studentName === "") {
      errors.studentName = "Student Name is required";
    }
    if (values.studentEmail === "") {
      errors.studentEmail = "Student Email is required";
    }
    return errors;
  };
  const [updating, setUpdating] = useState(false);
  const updateProfile = async (e) => {
    e.preventDefault();
    let values = { studentEmail, studentName };
    setErrors(validateErrors(values));

    if (Object.keys(validateErrors(values)).length > 0) {
      return;
    }

    try {
      setUpdating(true);
      const formData = new FormData();
      imageIsthis && formData.append("avatar", imageIsthis);
      formData.append("name", studentName);
      formData.append("email", studentEmail);

      // const data0 = await api.patch(
      //   `/user/profile/avatar/${studentId}`,
      //   formData
      // )

      const data = await api.patch(
        `/teacher/update/student/${match.params.id}`,
        formData
      );
      if (data.ok) {
        setRefresh(!refresh);
        setOpen(false);
        setUpdating(false);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      setUpdating(false);
    }
  };

  // student grades
  const [progress, setProgress] = useState([]);

  const getProgress = async () => {
    try {
      const data = await api.get(
        `/teacher/view/student/progress/${match.params.id}`
      );
      if (data.data.length > 0) {
        // const orderData = data.data.reverse();
        setProgress(data.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  useEffect(() => {
    getProgress();
  }, [match]);
  const [imageIs, setImageIs] = useState();
  const [imageIsthis, setImageIsthis] = useState();

  const imageChangeHandler = (e) => {
    const [file] = e.target.files;
    setImageIs(URL.createObjectURL(file));
    setImageIsthis(e.target.files[0]);
  };
  const uploadImage = async () => {
    try {
      const formData = new FormData();
      formData.append("avatar", imageIsthis);
      const res = await api.patch(
        `/user/profile/avatar/${studentId}`,
        formData
      );
      if (res && res.ok) {
        console.log(res);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="mainContainer">
      <GlobalDialog open={open} handleClose={handleClose}>
        <div className="dialog-invite">
          <div className="top-header-dialog">
            <img
              src={close}
              style={{ cursor: "pointer" }}
              alt="Close"
              onClick={handleClose}
            />
          </div>
          <form onSubmit={updateProfile}>
            <div className="image-section d-flex-column">
              <img
                src={imageIs ? imageIs : noImage}
                alt="PersonIconFinal"
                className="imageStudent"
              />
              <label htmlFor="image" className="image-change-label">
                Change Image
              </label>
              <input
                onChange={(e) => imageChangeHandler(e)}
                type="file"
                id="image"
              />
            </div>
            <div className="main">
              <div className="groupItem-invite">
                <label htmlFor="">student Name</label>
                <input
                  type="text"
                  value={studentName}
                  name="studentName"
                  onChange={(e) => setStudentName(e.target.value)}
                />
                {errors.studentName && (
                  <ErrorMessage message={errors.studentName} type="danger" />
                )}
              </div>

              <div className="groupItem-invite">
                <label htmlFor="">student Email</label>
                <input
                  type="text"
                  value={studentEmail}
                  name="studentEmail"
                  onChange={(e) => setStudentEmail(e.target.value)}
                />
                {errors.studentEmail && (
                  <ErrorMessage message={errors.studentEmail} type="danger" />
                )}
              </div>
            </div>
            <div
              className="btns d-flex-row"
              style={{ justifyContent: "flex-end", gap: "16px" }}
            >
              <button
                type="button"
                className="cancel-btn"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button disabled={updating} type="submit" className="invite-btn">
                {updating ? "Updating..." : "Save"}
              </button>
            </div>
          </form>
        </div>
      </GlobalDialog>
      <div className="mainDashboard">
        <Sidebar section="home" />
        <div className="main_section">
          <div className="main_section_inner">
            {/* <TopContent /> */}
            <div className="middle-Content middle-Content-Student">
              <div className="heading-classroom">
                <BreadCrumbList values={values} />
              </div>
              <div className="topstudent-section d-flex-row">
                <div className="left-student d-flex-row">
                  <img
                    className="girlImage"
                    src={imageIs ? imageIs : noImage}
                    alt=""
                  />
                  <div className="info">
                    <div className="heading">
                      <span>{studentDetails?.name}</span>
                      <img
                        src={edit}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onClick={() => setOpen(true)}
                      />
                    </div>
                    <div className="date">
                      {/* 24 March 2019 */}
                      {date}
                    </div>
                    <div className="generalInfo d-flex-row">
                      <div className="left">
                        <img className="imgStudent-section" src={sms} alt="" />
                        <span>{studentDetails?.email}</span>
                      </div>
                      <div className="right">
                        <img
                          className="imgStudent-section"
                          src={briefcase}
                          alt=""
                        />
                        <span>{className}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="pause-btn"
                  onClick={() =>
                    changeStatus(studentDetails._id, studentDetails.status)
                  }
                >
                  {studentDetails.status === "active" ? "Pause" : "Resume"}
                </button>
              </div>
              <div className="border_Bottom border_Bottom-1"></div>
              <div className="bottom-content">
                {/* <div className="box-content">
                  <div className="heading d-flex-row">
                    <div className="left">Attendance</div>
                    <div className="right">{attendancePercent.toFixed(0)}%</div>
                  </div>
                  <div className="bottom">
                    <div className="bar">
                      <div
                        className="progress"
                        style={{ maxWidth: attendancePercent }}
                      ></div>
                    </div>
                  </div>
                </div> */}

                {progress.map((progressIs, index) => (
                  <div className="box-content">
                    <div className="heading d-flex-row">
                      <div className="left">
                        Course <br /> Completed ({progressIs?.title})
                      </div>
                      <div className="right">
                        {" "}
                        {progressIs.studentProgress[index]?.progress}%
                      </div>
                    </div>
                    <div className="bottom">
                      <div className="bar">
                        <div
                          className="progress"
                          style={{
                            width: `${progressIs.studentProgress[index]?.progress}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="progress-bottom">
                <div className="progress-heading">Progress</div>
                <div className="statistics ">
                  <StatBox
                    title="Company Valuation"
                    value={studentDetails?.totalCampnayValuation}
                    image={starIcon}
                    prefix="$"
                  />
                  <StatBox
                    title="Company Reputation"
                    value={`${studentDetails?.totalcompanyReputation}`}
                    postfix="%"
                  />
                  <StatBox
                    title="Number of Employees"
                    value={studentDetails?.totalemployNumber}
                  />
                  <StatBox
                    title="Cash Balance"
                    value={studentDetails?.totalCash}
                    image={cashIcon}
                    prefix="$"
                  />
                  <StatBox
                    title="Employees' Happiness"
                    value={`${studentDetails?.totalemploySatisfaction}`}
                    postfix="%"
                  />
                </div>
              </div>
            </div>

            <RightSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Student;
