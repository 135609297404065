import React from 'react'
import TableCell from '@mui/material/TableCell'
import { Link } from 'react-router-dom'

export default function CalendarClassListCell({ row }) {
  return (
    <>
      <TableCell sx={{ marginTop: '10px' }} align='left' className='introRow'>
        {row.classRoom}
      </TableCell>
      <TableCell>
        <Link to={`/calendar/classes/${row.classRoom}`}>
          <div className='pause view-more'>View More</div>
        </Link>{' '}
      </TableCell>
    </>
  )
}
