import React, { useState, useEffect } from "react";
// import stXaviers from '../assets/stXaviers.svg'
// import logout from '../assets/logout.svg'
// import settings from '../assets/settings.svg'
// import music from '../assets/music.svg'
import plusIcon from "../assets/plusIcon.svg";
import arrowRight from "../assets/arrowRight.svg";
import { Link } from "react-router-dom";
import api from "../services/api";
import { format } from "date-fns";
import StudentRank from "./StudentRank";
import Announcement from "./Announcement";
import { GlobalDialog } from "./GlobalDialog";
import close from "../assets/close.svg";

const RightSidebar = ({ refresh, classIs }) => {
  const [open, setOpen] = useState(false);
  const [refresh1, setRefresh1] = useState(false);

  const handleOpen = () => setOpen(true);
  let schoolId = JSON.parse(localStorage.getItem("userData")).user.schoolId
    ?._id;

  const [announcements, setAnnouncements] = useState([]);
  const [allClasses, setAllClasses] = useState([]);
  let teacherId = JSON.parse(localStorage.getItem("userData")).user._id;

  const getAllClasses = async () => {
    try {
      const data = await api.get(`/teacher/view/all/classes/${teacherId}`);

      if (data && data.ok) {
        setAllClasses(data.allClasses);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getAllClasses();
  }, []);

  const getAnnouncements = async () => {
    try {
      let classNotifications = [];
      if (allClasses.length) {
        for (let i = 0; i < allClasses.length; i++) {
          const data = await api.get(
            `/teacher/get/announcement/school/${allClasses[i]._id}`
          );
          classNotifications.push(...data.data);
        }
      }
      const data1 = await api.get(
        `/teacher/get/announcement/school/announcement/${schoolId}`
      );
      if (data1 && data1.ok) {
        setAnnouncements([...classNotifications, ...data1.data]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getAnnouncements();
  }, [allClasses, refresh, refresh1]);

  // student rankings display
  const [ranks, setRanks] = useState([]);
  const [newValues, setNewValues] = useState([]);

  const getRanks = async () => {
    try {
      const data = await api.get(`/teacher/student/game-para/rank
      `);
      if (data && data.ok) {
        if (data.data.length > 0) {
          data.data.forEach((datum, id) => (datum.sNo = id + 1));
        }
        setRanks(data.data);
        setNewValues(data.data.slice(0, initial));
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getRanks();
  }, []);

  const [initial, setInitial] = useState(5);
  const viewMore = (digit) => {
    setInitial(initial + 5);
    setNewValues(ranks.slice(digit, initial + 5));
  };
  const viewLess = (digit) => {
    // setInitial(initial - 5)
    // console.log(digit, initial - 5)
    // setNewValues(values.slice(initial - 5, digit))
  };
  const [message, setMessage] = useState("");
  const [openMsg, setOpenMsg] = useState(false);
  const openMsgModel = (message) => {
    setMessage(message);
    setOpenMsg(true);
  };
  const closeMsgModal = () => {
    setOpenMsg(false);
  };
  return (
    <div className="right-Sidebar right-Sidebar-classroom">
      {openMsg && (
        <GlobalDialog open={openMsg} handleClose={closeMsgModal}>
          <div className="dialog-invite dialog-notify-msg">
            <div className="top-header-dialog">
              <img
                src={close}
                style={{ cursor: "pointer" }}
                alt="Close"
                onClick={closeMsgModal}
              />
            </div>
            <div className="msg-notification">{message}</div>
          </div>
        </GlobalDialog>
      )}
      <div className="rightSidebar-inner">
        <div className="inner-box">
          <div className="top">
            <div className="left">
              <div className="date-classroom">
                {format(new Date(), "dd MMMM")}
              </div>
              <div className="date-classroom date-classroom-year">
                {format(new Date(), "yyyy")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="announcement d-flex-row">
        <span className="announce">Announcement</span>
        <div onClick={() => setOpen(true)}>
          {" "}
          <img style={{ cursor: "pointer" }} src={plusIcon} alt="" />
        </div>
      </div>
      <div className="content-Boxes">
        {announcements.length > 0 &&
          announcements.map((announcement, index) => (
            <div
              className="box d-flex-row"
              onClick={() => openMsgModel(announcement.message)}
            >
              <div className="left d-flex-row">
                <div className="left d-flex-row">A</div>
                <div className="right">
                  <span className="annual">{announcement.title}</span>
                  {announcement.startDate ? (
                    <span className="date">
                      {" "}
                      {format(
                        new Date(announcement?.startDate),
                        "dd MMMM yyyy"
                      )}{" "}
                      at {format(new Date(announcement?.startDate), "h:mm a")}{" "}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <img src={arrowRight} alt="" />
            </div>
          ))}
        {/* <div className="studentScore">
          <div className="announce">Student Rank</div>
          <div className="scores">
            {newValues.length > 0 &&
              newValues.map((rank, index) => (
                <StudentRank
                  key={index}
                  id={rank.sNo}
                  totalCampnayValuation={rank.totalCampnayValuation}
                  name={rank?.name}
                  img={rank?.gameAvatar?.avatar?.largeImg}
                />
              ))}
          </div>
          {initial < ranks.length && (
            <div
              className="view-more-btn d-flex-row"
              onClick={() => viewMore(initial)}
            >
              View More
            </div>
          )}
        </div> */}
      </div>
      {open && (
        <Announcement
          setRefresh1={setRefresh1}
          refresh1={refresh1}
          handleOpen={handleOpen}
          open={open}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};

export default RightSidebar;
