import React, { useState, useEffect } from "react";
import BreadCrumbList from "../../components/BreadCrumbList";
import RightSidebar from "../../components/RightSidebar";
import Sidebar from "../../components/Sidebar";
import api from "../../services/api";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LeaderboardCard from "../../components/Leaderboard/LeaderboardCard";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const LeaderboardHome = () => {
  const [refresh, setRefresh] = useState(false);
  const values = [
    {
      name: "Dashboard",
      to: "/dashboard",
    },
    {
      name: "Leaderboard",
      to: "/leaderboard",
    },
  ];

  const [teacherClass, setTeacherClass] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");

  let teacherId = JSON.parse(localStorage.getItem("userData")).user._id;

  useEffect(() => {
    getTeacherClasses();
  }, []);

  const getTeacherClasses = async () => {
    try {
      const teacherClasses = await api.get(
        `/teacher/view/all/teacher/class/${teacherId}`
      );
      if (teacherClasses && teacherClasses.ok) {
        setTeacherClass(teacherClasses.data);
        setSelectedClass(teacherClasses.data[0]._id);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [allClassroomContent, setAllClassroomContent] = useState();
  const [initialWeek, setInitialWeek] = useState();
  const [leaderboardData, setLeaderboardData] = useState();

  const fetchClassRoomContent = async () => {
    try {
      const contentData = [];
      const res = await api.get(`/student/classroomContent/${selectedClass}`);
      if (res && res.ok) {
        contentData.push(res.data);
      }
      setAllClassroomContent(contentData);

      contentData.length && setInitialWeek(contentData[0][0]._id);
      getLeaderboard(contentData[0][0]._id);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (teacherClass && teacherClass.length > 0) {
      fetchClassRoomContent();
    }
  }, [selectedClass]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = async (id, index) => {
    getLeaderboard(id);
    setValue(index);
  };

  const getLeaderboard = async (id) => {
    try {
      const res = await api.get(
        `/teacher/view/leaderBoard/${selectedClass}/${id}`
      );
      if (res && res.ok) {
        setLeaderboardData(res.currentRank);
      }
    } catch (err) {
      console.error(err);
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <div className="mainContainer">
      <div className="mainDashboard">
        <Sidebar section="leaderboard" />
        <div className="main_section">
          <div className="main_section_inner">
            {/* <TopContent /> */}

            <div className="middle-Content middle-Content-Student">
              <div className="heading-classroom">
                <BreadCrumbList values={values} />
              </div>
              <div className="calendar-Container">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <select
                    name="class"
                    value={selectedClass}
                    onChange={(e) => setSelectedClass(e.target.value)}
                    className="select-2"
                  >
                    <option>Select class</option>
                    {teacherClass &&
                      teacherClass.length > 0 &&
                      teacherClass.map((className, id) => (
                        <option value={className._id}>
                          {className.className}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="classContentTab">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {allClassroomContent && allClassroomContent.length > 0
                    ? allClassroomContent[0].map((d, index) => {
                        return (
                          <Tab
                            label={d.title}
                            key={index}
                            {...a11yProps(index)}
                            onClick={() => handleClick(d._id, index)}
                          />
                        );
                      })
                    : ""}
                </Tabs>
                <TabPanel value={value} index={value}>
                  <div className="studentScore">
                    <div className="scores">
                      {leaderboardData && leaderboardData.length > 0
                        ? leaderboardData.map((rank, index) => {
                            return (
                              <LeaderboardCard
                                key={index}
                                id={index + 1}
                                data={rank}
                              />
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </TabPanel>
              </div>
            </div>
            <RightSidebar refresh={refresh} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeaderboardHome;
