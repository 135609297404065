import * as React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import api from '../services/api'
import { tableCellClasses } from '@mui/material/TableCell'

function Row(props) {
  const { row, key } = props
  const [open, setOpen] = React.useState(false)
  const ExpandMoreIcon = () => {
    return (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M4.70703 16.707L3.29282 15.2928L11.9999 6.58571L20.707 15.2928L19.2928 16.707L11.9999 9.41414L4.70703 16.707Z'
          fill='black'
          fill-opacity='0.2'
        />
      </svg>
    )
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {/* <TableCell component="th" scope="row">
          {row._id}
        </TableCell> */}
        <TableCell>{row.name} </TableCell>
        {/* <TableCell>{row.duration}</TableCell> */}
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <ExpandMoreIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            // padding: "0 150px",
            padding: '0px 20px',
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout='auto' unmountOnExit>
            {row.options.map((option, id) => (
              <div className='question' style={{ padding: '10px 0' }}>
                <div className='text'>{option.options}</div>
                <div
                  className='progress-cover'
                  style={{ width: `${option.noOfChoose}%` }}
                >
                  <div className='percentage'>{option.noOfChoose}</div>
                </div>
              </div>
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,

    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
}

const questions = [
  {
    number: '001',

    question: 'Which is the tallest animal in the world',
    options: [
      {
        option: 'lorem ipsucmsa safjlksdjfsjdfljsdf',
        correct: '1618(99%)',
        correctPercent: '99',
      },
      {
        option: 'lorem ipsucmsa safjlksdjfsjdfljsdf',
        correct: '168(40%)',
        correctPercent: '40',
      },
      {
        option: 'lorem ipsucmsa safjlksdjfsjdfljsdf',
        correct: '8(5%)',
        correctPercent: '5',
      },
      {
        option: 'lorem ipsucmsa safjlksdjfsjdfljsdf',
        correct: '118(49%)',
        correctPercent: '49',
      },
    ],
    duration: '15m10s',
  },
  {
    number: '001',

    question: 'Which is the tallest animal in the world',
    options: [
      {
        option: 'lorem ipsucmsa safjlksdjfsjdfljsdf',
        correct: '1618(99%)',
        correctPercent: '99',
      },
      {
        option: 'lorem ipsucmsa safjlksdjfsjdfljsdf',
        correct: '168(40%)',
        correctPercent: '40',
      },
      {
        option: 'lorem ipsucmsa safjlksdjfsjdfljsdf',
        correct: '8(5%)',
        correctPercent: '5',
      },
      {
        option: 'lorem ipsucmsa safjlksdjfsjdfljsdf',
        correct: '118(49%)',
        correctPercent: '49',
      },
    ],
    duration: '15m10s',
  },
  {
    number: '001',

    question: 'Which is the tallest animal in the world',
    options: [
      {
        option: 'lorem ipsucmsa safjlksdjfsjdfljsdf',
        correct: '1618(99%)',
        correctPercent: '99',
      },
      {
        option: 'lorem ipsucmsa safjlksdjfsjdfljsdf',
        correct: '168(40%)',
        correctPercent: '40',
      },
      {
        option: 'lorem ipsucmsa safjlksdjfsjdfljsdf',
        correct: '8(5%)',
        correctPercent: '5',
      },
      {
        option: 'lorem ipsucmsa safjlksdjfsjdfljsdf',
        correct: '118(49%)',
        correctPercent: '49',
      },
    ],
    duration: '15m10s',
  },
  {
    number: '001',

    question: 'Which is the tallest animal in the world',
    options: [
      {
        option: 'lorem ipsucmsa safjlksdjfsjdfljsdf',
        correct: '1618(99%)',
        correctPercent: '99',
      },
      {
        option: 'lorem ipsucmsa safjlksdjfsjdfljsdf',
        correct: '168(40%)',
        correctPercent: '40',
      },
      {
        option: 'lorem ipsucmsa safjlksdjfsjdfljsdf',
        correct: '8(5%)',
        correctPercent: '5',
      },
      {
        option: 'lorem ipsucmsa safjlksdjfsjdfljsdf',
        correct: '118(49%)',
        correctPercent: '49',
      },
    ],
    duration: '15m10s',
  },
]

export default function QuestionAnalytics({ decisionTreeData }) {
  const [questionslist, setQuestionsList] = React.useState([])
  const getQuestions = async () => {
    try {
      const data = await api.get('/teacher/gameAnalytics')
    } catch (error) {}
  }
  React.useEffect(() => {
    getQuestions()
  }, [])

  return (
    <TableContainer id='questions' component={Paper}>
      <Table
        aria-label='collapsible table'
        sx={{
          '& .MuiTableCell-head': {
            fontSize: '12px',
            color: 'black',

            fontFamily: 'Inter',
            borderTopColor: 'transparent',
            fontWeight: 500,
            letterSpacing: '0.0327273px',
            lineHeight: '9px',
            background: '#F5F5F5',
            // textAlign: 'center',
            //   border:"1px solid red"
          },
          [`& .${tableCellClasses.root}`]: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <TableHead>
          <TableRow>
            {/* <TableCell>Number</TableCell> */}
            <TableCell>Question</TableCell>
            {/* <TableCell>Average Reading Duration</TableCell> */}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            minWidth: 750,
            '& .MuiTableCell-body': {
              fontFamily: 'Inter',
              fontSize: '2px',
              fontWeight: 400,
              fontSize: '12px',
              // lineHeight: '9px',

              color: '#3B3B3B',
              // border:"none",
              // textAlign: 'center',
            },
            '& .MuiTableCell-body:hover': {
              // color: 'gray',
            },
          }}
        >
          {decisionTreeData && decisionTreeData.length
            ? decisionTreeData.map((row, id) => <Row key={id} row={row} />)
            : ''}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
