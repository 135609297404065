import React, { useState, useEffect } from 'react'
import Routes from './Routes'
import UserContext from './components/UserContext'
import { ToastContainer } from 'react-toastify'

const App = () => {
  const [teacherInfo, setTeacherInfo] = useState('')
  useEffect(() => {
    localStorage.getItem('userData') !== null &&
      setTeacherInfo(JSON.parse(localStorage.getItem('userData')))
  }, [localStorage.getItem('userData')])

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
      {/* <UserContext.Provider value={teacherInfo}> */}
      <Routes />
      {/* </UserContext.Provider> */}
    </>
  )
}

export default App
