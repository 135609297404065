import React, { useState, useEffect } from 'react'
import TableCell from '@mui/material/TableCell'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
export default function DashboardCell({ row }) {
  let teacherName = JSON.parse(localStorage.getItem('userData')).user.name
  return (
    <>
      <TableCell align='left'>
        <Link to={`/dashboard/classroom/${row._id}`}>{row?.className}</Link>{' '}
      </TableCell>
      <TableCell align='left'>
        {' '}
        {format(new Date(row.createdAt), 'dd MMMM yyyy')}
      </TableCell>
      <TableCell align='left'>{teacherName}</TableCell>

      <TableCell align='left'>{row?.student.length}</TableCell>
    </>
  )
}
