import React, { useState, useEffect, forwardRef } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import Sidebar from "../../components/Sidebar";
import TopContent from "../../components/TopContent";
import BreadCrumbList from "../../components/BreadCrumbList";
import RightSidebar from "../../components/RightSidebar";
import CalendarDialog from "../../components/CalendarDialog";
import closeIcon from "../../assets/closeIcon.svg";
import alignLeft from "../../assets/alignLeft.svg";
import arrowDown from "../../assets/arrowDown1.svg";

// import alignLeft from '../../assets/alignLeft.svg'
import calendar from "../../assets/calendar1.svg";
import Time from "../../assets/time.svg";
import DatePicker from "react-datepicker";
import api from "../../services/api";
import ErrorMessage from "../../components/ErrorMessage";
import "react-datepicker/dist/react-datepicker.css";
const CalendarHome = ({ history }) => {
  const [events, setEvents] = useState([]);
  const [announcementMode, setAnnouncementMode] = useState(false);
  const url = window.location.href;
  useEffect(() => {
    if (url.includes("announcement=true")) {
      setAnnouncementMode(true);
    }
  }, [url]);

  // errros handling
  const [errors, setErrors] = useState({});
  const validateForm = (values) => {
    let errors = {};
    if (!values.eventName) {
      errors.title = "Title is Required";
    }
    if (!values.description) {
      errors.description = "Description is Required";
    }
    let date1 = values.startDate.getTime();
    let date2 = values.endDate.getTime();
    if (date1 > date2) {
      errors.date = "Start Date cannot be greater than end Date";
    }
    return errors;
  };
  const [refresh, setRefresh] = useState(false);

  // get all event
  // const getAllEvents = async () => {
  //   try {
  //     const data = await api.get("/teacher/all/event");

  //     let allEvents = [];
  //     data?.data.forEach((item, index) => {
  //       let event = {
  //         id: "",
  //         title: "",
  //         start: "",
  //         end: "",
  //         backgroundColor: "#FCDED4",
  //       };
  //       event.id = item._id;
  //       event.title = item.eventName;
  //       event.start = item.startDate;
  //       event.end = item.endDate;
  //       event.description = item.description;
  //       allEvents.push(event);
  //     });

  //     setEvents(allEvents);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  let schoolId = JSON.parse(localStorage.getItem("userData")).user.schoolId
    ?._id;
  let teacherId = JSON.parse(localStorage.getItem("userData")).user._id;

  const [teacherClass, setTeacherClass] = useState([]);

  useEffect(() => {
    getTeacherClasses();
  }, []);

  const getTeacherClasses = async () => {
    try {
      const teacherClasses = await api.get(
        `/teacher/view/all/teacher/class/${teacherId}`
      );
      if (teacherClasses && teacherClasses.ok) {
        setTeacherClass(teacherClasses.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getAllEvents = async () => {
    try {
      let allEvents = [];

      const teacherClasses = await api.get(
        `/teacher/view/all/teacher/class/${teacherId}`
      );

      if (teacherClasses.data.length) {
        for (let i = 0; i < teacherClasses.data.length; i++) {
          const data = await api.get(
            `/teacher/get/announcement/school/${teacherClasses.data[i]._id}`
          );
          data?.data.forEach((resItem, index) => {
            let event = {
              id: "",
              title: "",
              start: "",
              end: "",
              backgroundColor: "#FCDED4",
              classId: "",
              massAnnouncement: "",
            };
            event.id = resItem._id;
            event.title = resItem.title;
            event.start = resItem.startDate;
            event.end = resItem.endDate;
            event.description = resItem.message;
            event.classId = resItem.classId;
            event.massAnnouncement = resItem.massAnnouncement;
            allEvents.push(event);
          });
        }
      }

      const schoolAnnouncement = await api.get(
        `/teacher/get/announcement/school/announcement/${schoolId}`
      );

      schoolAnnouncement?.data.forEach((item, index) => {
        let event = {
          id: "",
          title: "",
          start: "",
          end: "",
          backgroundColor: "#FCDED4",
          classId: "",
          massAnnouncement: "",
        };
        event.id = item._id;
        event.title = item.title;
        event.start = item.startDate;
        event.end = item.endDate;
        event.description = item.message;
        event.classId = item.classId;
        event.massAnnouncement = item.massAnnouncement;
        allEvents.push(event);
      });

      setEvents(allEvents);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllEvents();
  }, [refresh]);

  const [calendarEvent, setCalendarEvent] = useState({
    eventName: "",
    description: "",
  });
  const changeHandler = (e) => {
    setCalendarEvent((prevValue) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.value,
      };
    });
  };
  // refresh
  // event create
  // let schoolId = JSON.parse(localStorage.getItem("userData")).user.schoolId
  //   ?._id;

  // const submitHandler = async (e) => {
  //   e.preventDefault();

  //   const event = {
  //     ...calendarEvent,
  //     startDate,
  //     endDate,
  //   };
  //   setErrors(validateForm(event));
  //   if (Object.keys(validateForm(event)).length > 0) {
  //     return;
  //   } else
  //     try {
  //       if (announcementMode) {
  //         // title: title,
  //         // message: message,
  //         // schoolId: req.params.id,
  //         // image: filePath,
  //         const data = await api.post(`/teacher/add/announcement/${schoolId}`, {
  //           title: calendarEvent.eventName,
  //           message: calendarEvent.description,
  //         });
  //         console.log("data for announcement", data);

  //         if (data && data.ok) {
  //           setOpen(false);
  //           setErrors({});
  //           setCalendarEvent({});
  //           setStartDate(new Date());
  //           setEndDate(new Date());
  //           setRefresh(!refresh);
  //         }
  //         return;
  //       }
  //       if (mode === "save") {
  //         const data = await api.post("/teacher/add/event", {
  //           ...event,
  //         });
  //         if (data && data.ok) {
  //           setOpen(false);
  //           setErrors({});
  //           setCalendarEvent({});
  //           setStartDate(new Date());
  //           setEndDate(new Date());
  //           setRefresh(!refresh);
  //         }
  //       } else {
  //         const data = await api.patch(`/teacher/edit/event/${eventToUpdate}`, {
  //           ...event,
  //         });
  //         if (data && data.ok) {
  //           setOpen(false);
  //           setCalendarEvent({});
  //           setStartDate(new Date());
  //           setEndDate(new Date());
  //           setRefresh(!refresh);
  //           setErrors({});
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  // };

  const [classIs, setClassIs] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();

    const event = {
      ...calendarEvent,
      startDate,
      endDate,
    };
    setErrors(validateForm(event));
    if (Object.keys(validateForm(event)).length > 0) {
      return;
    } else
      try {
        if (classIs === "mass") {
          const resData = await api.post(
            `/teacher/add/MassAnnouncement`,
            // formData
            {
              title: calendarEvent.eventName,
              message: calendarEvent.description,
              date: startDate,
              endDate: endDate,
              classId: classIs,
            }
          );
          if (resData && resData.ok) {
            setOpen(false);
            setErrors({});
            setCalendarEvent({});
            setStartDate(new Date());
            setEndDate(new Date());
            setRefresh(!refresh);
          }
        } else {
          const resData = await api.post(
            `/teacher/add/announcement/${classIs}`,
            // formData
            {
              title: calendarEvent.eventName,
              message: calendarEvent.description,
              date: startDate,
              endDate: endDate,
              classId: classIs,
            }
          );
          if (resData && resData.ok) {
            setOpen(false);
            setErrors({});
            setCalendarEvent({});
            setStartDate(new Date());
            setEndDate(new Date());
            setRefresh(!refresh);
          }
        }
      } catch (error) {
        console.error(error);
      }
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [open, setOpen] = React.useState(false);
  const [eventToUpdate, seteventToUpdate] = useState("");
  const [mode, setMode] = useState("save");
  const eventInfo = (info) => {
    setMode("update");
    setOpen(true);
    seteventToUpdate(info.event.id);
    setCalendarEvent({
      eventName: info.event.title,
      description: info.event.extendedProps.description,
    });
    setStartDate(info.event.start);
    setEndDate(info.event.end);
    if (info.event.extendedProps.massAnnouncement) {
      setClassIs("mass");
    } else {
      setClassIs(info.event.extendedProps.classId);
    }
    // history.push('/calendar/classes')
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setMode("save");
    seteventToUpdate("");
    setCalendarEvent({});
    setEndDate(new Date());
    setErrors({});
  };

  const handleDateClick = (arg) => {
    setOpen(true);

    setStartDate(new Date(arg.date));
  };
  const values = [
    {
      name: "Dashboard",
      to: "/dashboard",
    },
    {
      name: "Calendar",
      to: "/calendar",
    },
  ];

  return (
    <div className="mainContainer">
      <div className="mainDashboard">
        <Sidebar section="calendar" />
        <div className="main_section">
          <div className="main_section_inner">
            {/* <TopContent /> */}

            <div className="middle-Content middle-Content-Student">
              <div className="heading-classroom">
                <BreadCrumbList values={values} />
              </div>
              <div className="calendar-Container">
                <CalendarDialog open={open} handleClose={handleClose}>
                  <form onSubmit={submitHandler}>
                    <div className="calendar">
                      <div className="top d-flex-row">
                        {/* <span>Title</span> */}
                        <input
                          type="text"
                          name="eventName"
                          value={calendarEvent.eventName}
                          onChange={changeHandler}
                          placeholder="Title"
                          className="calendarTitle"
                        />
                        <img
                          src={closeIcon}
                          style={{ cursor: "pointer" }}
                          alt="Close"
                          onClick={handleClose}
                        />
                      </div>
                      <ErrorMessage message={errors.title} type="danger" />
                      <div className="mainSection">
                        <div className="groupItem" style={{ marginTop: "0" }}>
                          <img src={alignLeft} alt="Align Left" />
                          <div className="content">
                            <textarea
                              name=""
                              id=""
                              cols="30"
                              name="description"
                              value={calendarEvent.description}
                              onChange={changeHandler}
                              rows="10"
                            ></textarea>
                          </div>
                        </div>
                        <ErrorMessage
                          message={errors.description}
                          type="danger"
                        />
                        <div className="groupItem">
                          <img src={Time} alt="Time" />
                          <div className="contentTime">
                            <div className="child-1">
                              <label>Start date</label>
                              <DatePicker
                                selected={startDate}
                                showTimeSelect
                                dateFormat="MMMM d, yyyy h:mm aa"
                                onChange={(date) => setStartDate(date)}
                                // customInput={<ExampleCustomInput />}
                              />
                              {/* <img src={arrowDown} alt='' className='arrow' /> */}
                            </div>
                            <div className="child-1">
                              <label>End date</label>
                              <DatePicker
                                selected={endDate}
                                showTimeSelect
                                dateFormat="MMMM d, yyyy h:mm aa"
                                onChange={(date) => setEndDate(date)}
                                // customInput={<ExampleCustomInput />}
                              />
                              {/* <img src={arrowDown} alt='' className='arrow' /> */}
                            </div>
                          </div>
                        </div>{" "}
                        <ErrorMessage message={errors.date} type="danger" />
                        <div className="groupItem">
                          <img src={calendar} alt="calendar" />
                          <select
                            name="class"
                            value={classIs}
                            onChange={(e) => setClassIs(e.target.value)}
                            className="select-2"
                          >
                            <option>Select class</option>
                            {teacherClass &&
                              teacherClass.length > 0 &&
                              teacherClass.map((className, id) => (
                                <option value={className._id}>
                                  {className.className}
                                </option>
                              ))}
                            <option value="mass">Mass Announcement</option>
                          </select>
                        </div>
                        <div className="button-section">
                          <button type="submit">
                            {mode === "save" ? "Create" : "Update"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>{" "}
                </CalendarDialog>
                <FullCalendar
                  plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    listPlugin,
                    interactionPlugin,
                  ]}
                  initialView="dayGridMonth"
                  dateClick={handleDateClick}
                  events={events}
                  eventClick={eventInfo}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                />
              </div>
            </div>
            <RightSidebar refresh={refresh} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarHome;
